<template>
  <a-spin :spinning="spinning">
    <div class="alert-timeline">
      <a-timeline v-if="alerts.length !== 0" style="margin-left: 164px">
        <a-icon slot="pendingDot" type="more" style="color: #1890ff"></a-icon>

        <div
          v-if="total > form.page * form.pageSize"
          slot="pending"
          style="margin-top: 16px"
        >
          <a
            @click="
              () => {
                this.form.page++
                this.fetch()
              }
            "
          >
            加载更多
          </a>
        </div>

        <a-timeline-item v-for="alert in alerts" :key="alert.id" color="red">
          <div style="margin-left: -178px; float: left">
            <div
              style="
                height: 20px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
                line-height: 20px;
                text-align: right;
              "
            >
              {{ alert.triggered_at }}
            </div>
            <div
              style="
                height: 17px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.5);
                line-height: 17px;
                text-align: right;
              "
            >
              {{ `持续${duration(alert.triggered_at, alert.recovered_at)}` }}
            </div>
          </div>

          <div
            style="
              background: #f5f9fd;
              border-radius: 11px;
              padding: 11px 20px 12px 20px;
              box-sizing: border-box;
              height: 64px;
              display: flex;
              align-items: center;
            "
          >
            <a-descriptions>
              <a slot="title" @click="$refs.alertDrawer.show(alert.id)">
                <div class="alert-title">{{ alert.name }}</div>
              </a>
              <template v-if="!sourceId">
                <a-descriptions-item label="资源类型">
                  {{ $t(`source_type.${alert.source_type}`) }}
                </a-descriptions-item>
                <a-descriptions-item label="资源">
                  <a @click="click(alert.source_type, alert.source.id)">
                    {{ alert.source?.name }}
                  </a>
                </a-descriptions-item>
              </template>
            </a-descriptions>

            <div class="severity-container">
              <alert-severity-tag :severity="alert.severity"></alert-severity-tag>
            </div>
          </div>
        </a-timeline-item>
      </a-timeline>
      <empty-component
        v-else
        :body-style="{
          height: '216px',
          marginTop: '22px'
        }"
        :description-style="{
          marginTop: '-28px'
        }"
      ></empty-component>
    <alert-drawer ref="alertDrawer"></alert-drawer>
    <network-device-drawer ref="networkDeviceDrawer"></network-device-drawer>
    <storage-drawer ref="storageDrawer"></storage-drawer>
    <server-drawer ref="serverDrawer"></server-drawer>
    <os-drawer ref="osDrawer"></os-drawer>
    <middleware-drawer ref="middlewareDrawer"></middleware-drawer>
    <database-drawer ref="databaseDrawer"></database-drawer>
    <application-drawer ref="applicationDrawer"></application-drawer>
    <site-drawer ref="siteDrawer"></site-drawer>
    <hypervisor-drawer ref="hypervisorDrawer"></hypervisor-drawer>
    </div>
  </a-spin>
</template>

<script>
import { getAlertList } from '@/api/alert'
import { getDatabaseAlertList } from '@/api/database'
import { getMiddlewareAlertList } from '@/api/middleware'
import { getNetworkDeviceAlertList } from '@/api/network-device'
import { getOSAlertList } from '@/api/os'
import { getStorageAlertList } from '@/api/storage'
import { getServerAlertList } from '@/api/server'
import { getServiceAlertList } from '@/api/service'
import { getSSLCertificateAlertList } from '@/api/ssl-certificate'
import { getDomainAlertList } from '@/api/domain'
import { getSiteAlertList } from '@/api/site'
import { getApplicationAlertList } from '@/api/application'
import AlertDrawer from '@/components/drawer/AlertDrawer'
import AlertSeverityTag from '@/components/tag/AlertSeverityTag'
import { humanAlertDuration } from '@/utils'
import EmptyComponent from '@/components/EmptyComponent.vue'

export default {
  name: 'AlertTimeline',
  components: {
    AlertDrawer,
    AlertSeverityTag,
    EmptyComponent,
    ApplicationDrawer: () => import('@/components/drawer/ApplicationDrawer'),
    DatabaseDrawer: () => import('@/components/drawer/DatabaseDrawer'),
    MiddlewareDrawer: () => import('@/components/drawer/MiddlewareDrawer'),
    NetworkDeviceDrawer: () =>
      import('@/components/drawer/NetworkDeviceDrawer'),
    'os-drawer': () => import('@/components/drawer/OSDrawer'),
    ServerDrawer: () => import('@/components/drawer/ServerDrawer'),
    SiteDrawer: () => import('@/components/drawer/SiteDrawer'),
    StorageDrawer: () => import('@/components/drawer/StorageDrawer'),
    HypervisorDrawer: () => import('@/components/drawer/HypervisorDrawer')
  },
  props: {
    recovered: {
      type: Boolean,
      default: false
    },
    severity: {
      type: String
    },
    sourceId: {
      type: String
    },
    sourceType: {
      type: String
    },
    deviceType: {
      type: [String, Array]
    },
    serviceId: {
      type: String
    },
    refresh: {
      type: Boolean
    }
  },
  data () {
    return {
      alerts: [],
      form: {
        page: 1,
        pageSize: 10
      },
      total: 0,
      spinning: false
    }
  },
  computed: {
    getFunc () {
      let func = getAlertList
      switch (this.sourceType) {
        case 'network_device':
          func = getNetworkDeviceAlertList
          break
        case 'server':
          func = getServerAlertList
          break
        case 'storage':
          func = getStorageAlertList
          break
        case 'os':
          func = getOSAlertList
          break
        case 'middleware':
          func = getMiddlewareAlertList
          break
        case 'database':
          func = getDatabaseAlertList
          break
        case 'application':
          func = getApplicationAlertList
          break
        case 'site':
          func = getSiteAlertList
          break
        case 'domain':
          func = getDomainAlertList
          break
        case 'ssl_certificate':
          func = getSSLCertificateAlertList
          break
        default:
          func = getAlertList
          break
      }
      return func
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.spinning = true
      const params = {
        recovered: this.recovered,
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.severity && this.severity !== 'all') {
        params.severity = this.severity
      }
      if (this.sourceType && this.getFunc === getAlertList && this.sourceType !== 'all') params.source_type = this.sourceType
      if (this.sourceId) {
        params.source_id = this.sourceId
      }
      if (this.deviceType) {
        params.device_type = this.deviceType
      }
      if (this.serviceId) {
        getServiceAlertList(this.serviceId, params).then(res => {
          const data = res.data
          this.alerts.push(...data.data)
          this.total = data.total
          this.$emit('total', this.total)
        }).finally(() => { this.spinning = false })
      } else {
        this.getFunc(params).then(res => {
          const data = res.data
          this.alerts.push(...data.data)
          this.total = data.total
          this.$emit('total', this.total)
        }).finally(() => { this.spinning = false })
      }
    },
    duration (triggeredAt, recoveredAt) {
      return humanAlertDuration(triggeredAt, recoveredAt)
    },
    click (sourceType, sourceId) {
      switch (sourceType) {
        case 'network_device':
          this.$refs.networkDeviceDrawer.show(sourceId)
          break
        case 'storage':
          this.$refs.storageDrawer.show(sourceId)
          break
        case 'server':
          this.$refs.serverDrawer.show(sourceId)
          break
        case 'os':
          this.$refs.osDrawer.show(sourceId)
          break
        case 'middleware':
          this.$refs.middlewareDrawer.show(sourceId)
          break
        case 'database':
          this.$refs.databaseDrawer.show(sourceId)
          break
        case 'application':
          this.$refs.applicationDrawer.show(sourceId)
          break
        case 'site':
          this.$refs.siteDrawer.show(sourceId)
          break
        case 'hypervisor':
          this.$refs.hypervisorDrawer.show(sourceId)
          break
        case 'switch':
          break
      }
    }
  },
  watch: {
    severity () {
      this.alerts = []
      this.form = {
        page: 1,
        pageSize: 10
      }
      this.fetch()
    },
    sourceType () {
      this.alerts = []
      this.form = {
        page: 1,
        pageSize: 10
      }
      this.fetch()
    },
    refresh () {
      this.alerts = []
      this.form = {
        page: 1,
        pageSize: 10
      }
      this.fetch()
    }

  }
}
</script>

<style lang="less">
.alert-timeline {
  .alert-title {
    // width: calc(100% - 48px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 17px;
  }

  .ant-timeline-item {
    padding: 0 0 10px 0;
  }
  .ant-timeline-item-pending {
    padding: 0;
  }
  .ant-descriptions-title {
    margin-bottom: 4px;
  }
  .ant-descriptions-item {
    padding: 0;
  }
}
</style>
